import { Component, ViewContainerRef, inject } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { getTranslationLoaded, ToastService } from '@ui/legacy-lib';
import { fadeOnEnterLeaveAnimation } from '@ui/legacy-lib';

import { I18nService, PageTitleService } from '@ui/legacy-lib';
import { AsyncPipe } from '@angular/common';
import { LoadingSpinnerComponent } from '@ui/legacy-lib';
import { ThemeDirective } from '@ui/legacy-lib';
import { ThemeComponent } from '@ui/legacy-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fadeOnEnterLeaveAnimation],
  imports: [
    AsyncPipe,
    LoadingSpinnerComponent,
    RouterOutlet,
    ThemeDirective,
    ThemeComponent
  ],
  standalone: true
})
export class AppComponent {
  toastService = inject(ToastService);
  viewContainerRef = inject(ViewContainerRef);
  private store = inject(Store);
  private activatedRoute = inject(ActivatedRoute);
  private pageTitleService = inject(PageTitleService);
  private i18nService = inject(I18nService);

  public finishedLoaded$: Observable<boolean>;

  public constructor() {
    const viewContainerRef = this.viewContainerRef;

    this.pageTitleService.run(this.activatedRoute);
    this.i18nService.loadDefaultTranslations();
    this.toastService.setRootViewContainerRef(viewContainerRef);

    this.finishedLoaded$ = this.store.select(getTranslationLoaded);
  }
}
