import { Component } from '@angular/core';
import { footerConfig } from 'admin/config';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from 'libs/components/legacy';

@Component({
  selector: 'app-fullscreen-not-authenticated',
  templateUrl: './fullscreen-not-authenticated.component.html',
  styleUrls: ['./fullscreen-not-authenticated.component.scss'],
  imports: [RouterOutlet, FooterComponent],
  standalone: true
})
export class FullscreenNotAuthenticatedComponent {
  public footerLinks = footerConfig.links;
}
