import { Injectable } from '@angular/core';

@Injectable()
export class PermissionService {
  private permissions: string[] = [];

  public setPermissions(permissions: string[]) {
    this.permissions = permissions;
  }

  public hasPermission(permission: string) {
    return this.permissions.indexOf(permission) > -1;
  }
}
