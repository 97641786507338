import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from 'admin-env';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { AppComponent } from 'admin/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRouterModule } from 'admin/app.router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from 'admin/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import {
  ComponentsModule as SharedComponentsModule,
  ComponentsModuleConfig
} from '@ui/legacy-lib';
import { PipesModule } from '@ui/legacy-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideRouterStore } from '@ngrx/router-store';
import { CustomSerializer } from '@ui/legacy-lib';
import {
  AuthTokenService,
  BodyService,
  CONSTANTS_LOADER,
  ConstantsParserService,
  FreshchatWidgetService,
  InfrastructureConfig,
  InfrastructureModule
} from '@ui/legacy-lib';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { PortalsModule } from '@ui/legacy-lib';
import { ProductsModule, ProductsModuleConfig } from '@ui/legacy-lib';
import { TablesModule } from 'admin/screens/tables/tables.module';
import { LandlordModule } from 'admin/screens/landlord/landlord.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ThemeModule } from '@ui/legacy-lib';
import { ImmomioTheme } from '@ui/legacy-lib';
import { ThemeUrls } from '@ui/legacy-lib';
import { languageConfig, storageKeys } from 'admin/config';
import { AddonType } from '@ui/shared/models';
import { PriosetFacade } from 'admin/+state/service';
import { AttachmentService, DateTimeService } from '@ui/legacy-lib';
import {
  ConstantsLoaderService,
  InvoiceService,
  PermissionService
} from 'admin/core';
import { provideStore } from '@ngrx/store';
import { appReducers, effects } from 'admin/+state';
import { provideEffects } from '@ngrx/effects';
import { authGuards } from './app/auth';
import { guards } from './app/guards';

if (environment.production) {
  enableProdMode();
}

const productsModuleConfig: ProductsModuleConfig = {
  addonsStrategy: {
    freeAgents: 1,
    agentType: AddonType.AGENT,
    homepageModuleRestType: AddonType.HOMEPAGE_MODULE_REST,
    customerCooperationType: AddonType.CUSTOMER_COOPERATION,
    notSupported: [AddonType.DATAINSIGHTS, AddonType.SHORTLIST]
  }
};

const sharedComponentsModuleConfig: ComponentsModuleConfig = {
  googleApiConfig: {
    apiKey: environment.google_api_key
  }
};

const infrastructureConfig: InfrastructureConfig = {
  environment,
  storageKeys,
  languages: languageConfig
};

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      ServiceWorkerModule.register('/ngsw-worker.js', {
        enabled:
          environment.deploymentEnv === 'production' ||
          environment.deploymentEnv === 'sandbox'
      }),
      AppRouterModule,
      TranslateModule.forRoot(),
      AuthModule,
      BrowserModule,
      BrowserAnimationsModule,
      CommonModule,
      SharedComponentsModule.forRoot(sharedComponentsModuleConfig),
      PipesModule,
      FormsModule,
      InfrastructureModule.forRoot(infrastructureConfig),
      ReactiveFormsModule,
      NgbModule,
      PortalsModule.forRoot(environment.backend_url),
      ProductsModule.forRoot(productsModuleConfig),
      TablesModule,
      LandlordModule,
      AngularSvgIconModule.forRoot(),
      ThemeModule.forRoot({
        themes: [ImmomioTheme],
        active: ThemeUrls.IMMOMIO
      })
    ),
    ...guards,
    ...authGuards,
    PriosetFacade,
    DateTimeService,
    AttachmentService,
    FreshchatWidgetService,
    AuthTokenService,
    BodyService,
    { provide: CONSTANTS_LOADER, useClass: ConstantsLoaderService },
    provideStore(appReducers),
    provideEffects(effects),
    !environment.production
      ? provideStoreDevtools({ connectInZone: true })
      : [],
    provideRouterStore({
      serializer: CustomSerializer
    }),
    PermissionService,
    ConstantsParserService,
    InvoiceService
  ]
}).catch(err => console.error(err));
