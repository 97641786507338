import { Injectable } from '@angular/core';

import {
  PropertyPortalStates,
  PortalCredential,
  PropertyPortal
} from '@ui/shared/models';

@Injectable()
export class PropertyPortalService {
  public getPropertyPortalsDiff(
    portals: PropertyPortal[] = [],
    credentials: PortalCredential[] = []
  ) {
    /**
     * We want to create PropertyPortal for given Property when:
     *  a) there is no PropertyPortal related to the Credential yet.
     * In other words, true is returned for Credential when find() function
     * cannot find existing PropertyPortal which is ACTIVE or PENDING.
     * Then we map Credential to PropertyPortal to ensure property model structure.
     */
    const toCreate = credentials
      .filter(
        credential =>
          !portals.find(portal => portal.credentials.id === credential.id)
      )
      .map(this.createPropertyPortal);

    /**
     * We want to remove PropertyPortal when it is NOT active and
     * related Credential is not found (has been deselected by the user).
     * In other words true is returned for PropertyPortal when it is DEACTIVATED or ERROR AND
     * find() function cannot find related Credential.
     */
    const toRemove = portals.filter(
      portal =>
        !this.isActive(portal) &&
        !credentials.find(credential => portal.credentials.id === credential.id)
    );

    return {
      toCreate,
      toRemove
    };
  }

  public createPropertyPortal(credential: PortalCredential) {
    return {
      credentials: { ...credential },
      portal: credential.portal
    } as PropertyPortal;
  }

  public isActive(portal: PropertyPortal) {
    return (
      portal.state === PropertyPortalStates.ACTIVE ||
      portal.state === PropertyPortalStates.PENDING ||
      portal.state === PropertyPortalStates.ERROR
    );
  }
}
