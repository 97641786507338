import {
  Component,
  Input,
  OnInit,
  forwardRef,
  Output,
  EventEmitter
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PortalConfig, PortalCredential } from '@ui/shared/models';
import { AvailablePortalComponent } from '../available-portal/available-portal.component';

@Component({
  selector: 'app-add-portal-form',
  templateUrl: './add-portal-form.component.html',
  styleUrls: ['./add-portal-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddPortalFormComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [AvailablePortalComponent]
})
export class AddPortalFormComponent implements OnInit, ControlValueAccessor {
  @Input() public availablePortals: PortalConfig[] = [];
  @Output() getIs24URL = new EventEmitter();

  public value: PortalCredential = {};

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public ngOnInit() {
    this.onChange(this.value);
    this.onTouch();
  }

  public isSelected(portalConfig: PortalConfig) {
    return this.value.portal === portalConfig.portalId;
  }

  public onToggle(credential: PortalCredential) {
    this.value = this.value.portal === credential.portal ? {} : credential;

    this.onChange(this.value);
    this.onTouch();
  }

  public onUpdate(credential: PortalCredential) {
    this.value = credential;

    this.onChange(this.value);
    this.onTouch();
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public writeValue(value: PortalCredential) {
    this.value = value;
  }

  public onGetIs24URL() {
    this.getIs24URL.emit();
  }
}
