import { PortalType } from '@ui/shared/models';

export const isImmoscout = type =>
  type === PortalType.IMMOBILIENSCOUT24_DE ||
  type === PortalType.IMMOBILIENSCOUT24_GC_DE ||
  type === PortalType.IMMOBILIENSCOUT24_HM_DE;

export const isHomepageModule = type => type === PortalType.WORDPRESS_PLUGIN;
export const isResident = type => type === PortalType.RESIDENT_APP;
export const isWillhaben = type => type === PortalType.WILLHABEN;
export const isImmobilie1 = type => type === PortalType.IMMOBILIE1;
