import { enableProdMode, importProvidersFrom } from '@angular/core';

import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';

import { ServiceWorkerModule } from '@angular/service-worker';

import { TranslateModule } from '@ngx-translate/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import {
  AttachmentService,
  AuthTokenService,
  BodyService,
  ComponentsModule,
  ComponentsModuleConfig,
  CONSTANTS_LOADER,
  ConstantsParserService,
  CustomSerializer,
  DateTimeService,
  FreshchatWidgetService,
  ImmomioTheme,
  InfrastructureConfig,
  InfrastructureModule,
  PipesModule,
  PortalsModule,
  ProductsModule,
  ProductsModuleConfig,
  ThemeModule,
  ThemeUrls
} from '@ui/legacy-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideRouterStore } from '@ngrx/router-store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { AddonType } from '@ui/shared/models';

import { provideStore } from '@ngrx/store';

import { provideEffects } from '@ngrx/effects';
import { provideHttpClient } from '@angular/common/http';
import { authGuards } from './app/auth';
import { guards } from './app/guards';
import { languageConfig, storageKeys } from './app/config';
import { AppComponent } from './app/app.component';
import { AppRouterModule } from './app/app.router';
import { AuthModule } from './app/auth/auth.module';
import { TablesModule } from './app/screens/tables/tables.module';
import { LandlordModule } from './app/screens/landlord/landlord.module';
import { PriosetFacade } from './app/+state/service';
import {
  ConstantsLoaderService,
  InvoiceService,
  PermissionService
} from './app/core';
import { appReducers, effects } from './app/+state';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const productsModuleConfig: ProductsModuleConfig = {
  addonsStrategy: {
    freeAgents: 1,
    agentType: AddonType.AGENT,
    homepageModuleRestType: AddonType.HOMEPAGE_MODULE_REST,
    customerCooperationType: AddonType.CUSTOMER_COOPERATION,
    notSupported: [AddonType.DATAINSIGHTS, AddonType.SHORTLIST]
  }
};

const componentsModuleConfig: ComponentsModuleConfig = {
  googleApiConfig: {
    apiKey: environment.google_api_key
  }
};

const infrastructureConfig: InfrastructureConfig = {
  environment,
  storageKeys,
  languages: languageConfig
};

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      ServiceWorkerModule.register('/ngsw-worker.js', {
        enabled:
          environment.deploymentEnv === 'production' ||
          environment.deploymentEnv === 'sandbox'
      }),
      AppRouterModule,
      TranslateModule.forRoot(),
      AuthModule,
      BrowserModule,
      BrowserAnimationsModule,
      CommonModule,
      ComponentsModule.forRoot(componentsModuleConfig),
      PipesModule,
      FormsModule,
      InfrastructureModule.forRoot(infrastructureConfig),
      ReactiveFormsModule.withConfig({
        callSetDisabledState: 'whenDisabledForLegacyCode'
      }),
      NgbModule,
      PortalsModule.forRoot(environment.backend_url),
      ProductsModule.forRoot(productsModuleConfig),
      TablesModule,
      LandlordModule,
      AngularSvgIconModule.forRoot(),
      ThemeModule.forRoot({
        themes: [ImmomioTheme],
        active: ThemeUrls.IMMOMIO
      })
    ),
    provideHttpClient(),
    ...guards,
    ...authGuards,
    PriosetFacade,
    DateTimeService,
    AttachmentService,
    FreshchatWidgetService,
    AuthTokenService,
    BodyService,
    { provide: CONSTANTS_LOADER, useClass: ConstantsLoaderService },
    provideStore(appReducers),
    provideEffects(effects),
    !environment.production
      ? provideStoreDevtools({ connectInZone: true })
      : [],
    provideRouterStore({
      serializer: CustomSerializer
    }),
    PermissionService,
    ConstantsParserService,
    InvoiceService
  ]
}).catch(err => console.error(err));
