import { inject, ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularSvgIconModule } from 'angular-svg-icon';

import { TranslateModule } from '@ngx-translate/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PortalConfig } from '@ui/shared/models';

import { ComponentsModule } from 'libs/components';

import { RouterModule } from '@angular/router';
import { PortalCredentialDetailsWithProviderComponent } from 'libs/portals/components/portal-credential-details-with-provider/portal-credential-details-with-provider.component';
import { portalsConfig } from './portals.config';

import {
  CREDENTIAL_VALIDATOR,
  CredentialValidatorService,
  FTP_CONNECTION_VALIDATOR,
  PropertyPortalService
} from './services';

import {
  AddPortalFormComponent,
  AvailablePortalComponent,
  HomepageModuleCredentialDetailsComponent,
  HomepageModuleModalComponent,
  ImmoscoutCredentialsDetailsComponent,
  PortalCredentialComponent,
  PortalCredentialDetailsComponent,
  PortalCredentialListComponent,
  PortalHomepageListComponent,
  ResidentCredentialsDetailsComponent
} from './components';

import { BACKEND_URL, PORTALS_CONFIG, PORTALS_CONFIG_MAP } from './tokens';

const services = [PropertyPortalService, CredentialValidatorService];

const components = [
  PortalCredentialDetailsComponent,
  ImmoscoutCredentialsDetailsComponent,
  HomepageModuleCredentialDetailsComponent,
  AvailablePortalComponent,
  PortalCredentialComponent,
  AddPortalFormComponent,
  PortalCredentialListComponent,
  PortalHomepageListComponent,
  HomepageModuleModalComponent,
  ResidentCredentialsDetailsComponent,
  PortalCredentialDetailsWithProviderComponent
];

export function portalsConfigMapFactory(configs: PortalConfig[]) {
  return configs.reduce((total, current) => {
    return {
      ...total,
      [current.portalId]: current
    };
  }, {}) as { [key: string]: PortalConfig };
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    TranslateModule,
    NgbModule,
    ComponentsModule,
    RouterModule,
    ...components
  ],
  exports: [...components]
})
export class PortalsModule {
  public static forRoot(
    backendUrl: string
  ): ModuleWithProviders<PortalsModule> {
    return {
      ngModule: PortalsModule,
      providers: [
        { provide: BACKEND_URL, useValue: backendUrl },
        { provide: PORTALS_CONFIG, useValue: portalsConfig },
        {
          provide: PORTALS_CONFIG_MAP,
          useFactory: portalsConfigMapFactory,
          deps: [PORTALS_CONFIG]
        },
        ...services
      ]
    };
  }

  constructor() {
    const credentialValidator = inject(CREDENTIAL_VALIDATOR, {
      optional: true
    });
    const ftpConnectionValidator = inject(FTP_CONNECTION_VALIDATOR, {
      optional: true
    });

    if (!credentialValidator || !ftpConnectionValidator) {
      console.warn(
        'CredentialValidator or ftpConnectionValidator have not been provided!'
      );
    }
  }
}
