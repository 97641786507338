import { Component, OnInit, Input, forwardRef, inject } from '@angular/core';
import {
  Validators,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';

import { UntilDestroy } from '@ngneat/until-destroy';

import { PortalConfig, PortalCredential } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';
import { BasePortalCredentialControl } from '../../controls';

@UntilDestroy()
@Component({
  selector: 'app-resident-credential-details',
  templateUrl: './resident-credential-details.component.html',
  styleUrls: ['./resident-credential-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ResidentCredentialsDetailsComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ComponentsModule, TranslateModule]
})
export class ResidentCredentialsDetailsComponent
  extends BasePortalCredentialControl
  implements OnInit
{
  private fb = inject(FormBuilder);

  @Input() public portalConfig: PortalConfig;
  @Input() public credential: PortalCredential;

  constructor() {
    super();

    this.form = this.fb.group({
      name: ['', Validators.required],
      properties: {}
    });
  }

  public ngOnInit() {
    if (this.credential) {
      this.form.patchValue(this.credential);
    }

    this.form.valueChanges.subscribe(credential => {
      this.value = { ...credential };

      this.onChange(this.value);
      this.onTouch();
    });
  }
}
