import { Injectable, inject } from '@angular/core';

import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { AuthTokenService } from 'libs/infrastructure';

@Injectable()
export class LoginPageGuard {
  private authService = inject(AuthTokenService);
  private store = inject<Store<fromBaseState.AppState>>(Store);

  canActivate(): Observable<boolean> {
    const token = this.authService.getToken().access_token;

    // if there is no token stored then it means that user can view /login page
    if (!token) {
      return of(true);
    }

    // if token present redirect user to overview, don't let him see /login page
    this.store.dispatch(new fromBaseState.Go({ path: ['tables'] }));
    return of(false);
  }
}
