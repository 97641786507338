import { ControlValueAccessor, FormGroup } from '@angular/forms';

import { PortalCredential } from '@ui/shared/models';

export abstract class BasePortalCredentialControl
  implements ControlValueAccessor
{
  public value: PortalCredential = {};

  public form: FormGroup;

  public get propertiesControl() {
    return this.form.get('properties') as FormGroup;
  }

  protected onChange = (value: unknown) => value;
  protected onTouch = () => null;

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public writeValue(value: PortalCredential) {
    this.value = value;
  }

  protected setCredentialValidationError(
    validationControlName: string,
    hasError = false
  ) {
    const validationControl = this.propertiesControl.get(validationControlName);
    const existingErrors = validationControl.errors || {};

    validationControl.setErrors({
      ...existingErrors,
      invalidCredentials: hasError
    });
  }
}
