<form [formGroup]="form">
  <app-form-field>
    <app-form-field-label>{{
      'PORTAL_NAME_L' | translate
    }}</app-form-field-label>
    <input
      appInput
      type="text"
      required
      [placeholder]="'TYPE_PORTAL_NAME_L' | translate"
      formControlName="name"
      class="form-control mb10"
    />
  </app-form-field>
  <ng-container formGroupName="properties">
    <app-form-field>
      <app-form-field-label>{{
        'PORTAL_VERIFICATION_CODE_L' | translate
      }}</app-form-field-label>
      <input
        appInput
        type="text"
        required
        [placeholder]="'TYPE_VERIFICATION_CODE_L' | translate"
        formControlName="verificationCode"
        class="form-control mb10"
      />
    </app-form-field>
    @if (includeChannel) {
      <app-form-field>
        <app-form-field-label>{{
          'PORTAL_CHANNEL_L' | translate
        }}</app-form-field-label>
        <input
          appInput
          type="text"
          required
          [placeholder]="'TYPE_PORTAL_CHANNEL_L' | translate"
          formControlName="channel"
          class="form-control mb10"
        />
      </app-form-field>
    }
  </ng-container>
</form>

<div class="fallback-link__container">
  <span>{{ 'IMMOSCOUT_IFRAME_FALLBACK_MESAGE_L' | translate }}</span>
  <app-button class="btn link p0 m0" (clickEvent)="onGetIs24URL()">
    {{ 'IMMOSCOUT_IFRAME_FALLBACK_LINK_L' | translate }}
  </app-button>
  <span>{{ 'IMMOSCOUT_IFRAME_FALLBACK_FURTHER_DETAILS_L' | translate }}</span>
</div>
