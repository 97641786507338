<div class="main-container container-primary">
  <app-header
    class="header"
    [navigationOpen]="navigationOpen"
    [profileSettingsOpen]="profileSettingsOpen"
    (navigationToggleClick)="onNavigationToggleClick($event)"
    (profileSettingsToggleClick)="onProfileSettingsToggleClick($event)"
  >
  </app-header>

  <div class="sidenav-container">
    <app-navigation
      [navigationItems]="navigationItems"
      [narrow]="navigationStoredSettings?.narrow"
      [(open)]="navigationOpen"
      (narrowManualChange)="onNavigationNarrowManualChange($event)"
    ></app-navigation>

    <div class="sidenav-container__main-content">
      <div class="content-container">
        <router-outlet></router-outlet>
      </div>
    </div>

    <app-navigation
      [navigationItems]="profileSettingsItems"
      [alignment]="'right'"
      [overlay]="true"
      [(open)]="profileSettingsOpen"
    >
      <app-footer
        navigationFooter
        [sideNavMode]="true"
        [links]="footerLinks"
      ></app-footer>
    </app-navigation>
  </div>
</div>
