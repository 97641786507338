import { Component, OnInit, inject } from '@angular/core';
import { HomepageModule, HomepageModuleType } from '@ui/shared/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';

@Component({
  selector: 'app-homepage-link-modal',
  templateUrl: './homepage-link-modal.component.html',
  styleUrls: ['./homepage-link-modal.component.scss'],
  standalone: true,
  imports: [ComponentsModule, FormsModule, ReactiveFormsModule, TranslateModule]
})
export class HomepageModuleModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);

  public link: HomepageModule;
  public formGroup = new FormGroup({
    id: new FormControl(),
    name: new FormControl('', Validators.required),
    type: new FormControl(HomepageModuleType.CARD)
  });
  public homepageModuleTypes = [
    {
      value: HomepageModuleType.CARD,
      name: 'homepage_module.portal_type_tiles'
    },
    {
      value: HomepageModuleType.LIST,
      name: 'homepage_module.portal_type_table'
    }
  ];

  public ngOnInit(): void {
    if (this.link) {
      const { id, name, type } = this.link;
      this.formGroup.patchValue({
        id,
        name,
        type
      });
    }
  }

  public save(): void {
    const { id, name, type } = this.formGroup.value as HomepageModule;
    this.ngbActiveModal.close({
      id,
      name,
      type
    });
  }

  public get typeControl(): FormControl {
    return this.formGroup.get('type') as FormControl;
  }

  public dismiss(): void {
    this.ngbActiveModal.dismiss();
  }
}
