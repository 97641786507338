import { inject, Injectable, InjectionToken } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PortalCredential } from '@ui/shared/models';

export interface ICredentialValidator {
  validateCredential: (credential: PortalCredential) => Observable<boolean>;
}

export interface IFtpConnectionValidator {
  validateFtpConnection: (credential: PortalCredential) => Observable<boolean>;
}

export const CREDENTIAL_VALIDATOR = new InjectionToken<ICredentialValidator>(
  'CREDENTIAL_VALIDATOR'
);
export const FTP_CONNECTION_VALIDATOR =
  new InjectionToken<IFtpConnectionValidator>('FTP_CONNECTION_VALIDATOR');

@Injectable()
export class CredentialValidatorService {
  private credentialValidator = inject(CREDENTIAL_VALIDATOR);
  private ftpConnectionValidator = inject(FTP_CONNECTION_VALIDATOR);

  private _validationError$: Subject<string> = new Subject<string>();

  public get validationError$() {
    return this._validationError$.asObservable();
  }

  public validateCredential(credential: PortalCredential) {
    this._validationError$.next('');

    return this.credentialValidator.validateCredential(credential).pipe(
      tap(valid => {
        if (!valid) {
          this._validationError$.next(credential.portal);
        }
      })
    );
  }

  public emitValidationError(portalId: string) {
    this._validationError$.next(portalId);
  }

  public validateFtpConnection(credential: PortalCredential) {
    return this.ftpConnectionValidator.validateFtpConnection(credential);
  }
}
