import { Component, OnInit, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { LocalStorageService, UpdateApplicationSuccess } from '@ui/legacy-lib';
import {
  BaseNavigationItem as NavigationItem,
  BreakPointsMaxInPx
} from '@ui/shared/models';
import { ModalService } from '@ui/legacy-lib';
import { NewUpdateBannerComponent } from '@ui/legacy-lib';

import * as fromAppState from 'admin/+state';
import {
  footerConfig,
  navigationConfig,
  profileSettingsNavigationConfig,
  storageKeys
} from 'admin/config';
import { PermissionService } from 'admin/core';
import { FooterComponent, HeaderComponent } from '@ui/legacy-lib';
import { NavigationComponent } from '@ui/legacy-lib';
import { RouterOutlet } from '@angular/router';

interface NavigationStoredSettings {
  narrow?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-fullscreen-authenticated',
  templateUrl: './fullscreen-authenticated.component.html',
  styleUrls: ['./fullscreen-authenticated.component.scss'],
  imports: [
    HeaderComponent,
    NavigationComponent,
    RouterOutlet,
    FooterComponent
  ],
  standalone: true
})
export class FullscreenAuthenticatedComponent implements OnInit {
  private permissionService = inject(PermissionService);
  private store = inject(Store);
  private swUpdate = inject(SwUpdate);
  private modalService = inject(ModalService);
  private observer = inject(BreakpointObserver);
  private storageService = inject(LocalStorageService);

  public navigationOpen: boolean;
  public navigationStoredSettings: NavigationStoredSettings;
  public profileSettingsOpen: boolean;
  private _mobileView: boolean;

  public navigationItems: NavigationItem[];
  public profileSettingsItems: NavigationItem[] =
    profileSettingsNavigationConfig;
  public footerLinks = footerConfig.links;

  public ngOnInit() {
    this.store.dispatch(new fromAppState.LoadAvailableProducts(1));

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(
          filter(
            (evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
          )
        )
        .subscribe(() =>
          this.modalService
            .open<NewUpdateBannerComponent>(NewUpdateBannerComponent, {
              backdrop: 'static'
            })
            .onClose()
            .subscribe(() =>
              this.store.dispatch(new UpdateApplicationSuccess())
            )
        );
    }

    this.navigationItems = navigationConfig.filter(
      item =>
        !!(
          !item.withPermission ||
          this.permissionService.hasPermission(item.withPermission)
        )
    );

    this.observer
      .observe(`(max-width: ${BreakPointsMaxInPx.MD}px)`)
      .pipe(untilDestroyed(this))
      .subscribe(result => (this._mobileView = result.matches));
  }

  public onNavigationToggleClick(event: Event) {
    event.stopPropagation();

    this.navigationOpen = !this.navigationOpen;

    if (this.navigationOpen) this.profileSettingsOpen = false;
  }

  public onNavigationNarrowManualChange(narrow: boolean) {
    this.storageService.setItem(storageKeys.navigation, {
      ...this.navigationStoredSettings,
      narrow
    });
  }

  public onProfileSettingsToggleClick(event: Event) {
    event.stopPropagation();

    this.profileSettingsOpen = !this.profileSettingsOpen;

    if (this._mobileView && this.profileSettingsOpen)
      this.navigationOpen = false;
  }
}
