import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConstantsGuard, TranslationsGuard } from 'libs/infrastructure';

import { AuthGuard } from 'admin/auth';
import { NAVIGATION_LINK } from 'admin/config';

import * as Layouts from './layouts';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login'
  },
  {
    path: '',
    canActivate: [TranslationsGuard],
    component: Layouts.SidebarNotAuthenticatedComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('admin/auth/auth.module').then(module => module.AuthModule)
      }
    ]
  },
  {
    path: '',
    component: Layouts.FullscreenAuthenticatedComponent,
    canActivate: [TranslationsGuard, AuthGuard, ConstantsGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: NAVIGATION_LINK.TABLES
      },
      {
        path: 'tables',
        loadChildren: () =>
          import('./screens/tables/tables.module').then(
            module => module.TablesModule
          ),
        data: {
          title: 'navigation.tables_l'
        }
      },
      {
        path: 'landlord',
        data: {
          title: 'navigation.create_landlord_l'
        },
        loadChildren: () =>
          import('./screens/landlord/landlord.module').then(
            module => module.LandlordModule
          )
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('./screens/manage/manage.routes').then(module => module.ROUTES)
      },
      {
        path: '**',
        redirectTo: NAVIGATION_LINK.TABLES
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRouterModule {}
