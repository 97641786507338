import {
  ImmomioPortalType,
  ImmoscoutTypes,
  PortalConfig,
  PortalType
} from '@ui/shared/models';

export const portalsConfig: PortalConfig[] = [
  {
    portalId: PortalType.WORDPRESS_PLUGIN,
    fullName: 'HOMEPAGE_MODULE_PORTAL_DISPLAY_NAME_L',
    userNameLabel: 'HOMEPAGE_MODULE_USERNAME_L',
    passwordLabel: 'HOMEPAGE_MODULE_PASSWORD_L',
    imgUrl: 'assets/images/portals/homepage_module_thumbnail.svg',
    skipValidation: false,
    permission: 'homepage_module'
  },
  {
    portalId: ImmomioPortalType.HOMEPAGE_MODULE,
    fullName: 'IMMOMIO_HOMEPAGE_MODULE_PORTAL_DISPLAY_NAME_L',
    imgUrl: 'assets/images/portals/homepage_module_thumbnail.svg',
    skipValidation: true
  },
  {
    portalId: PortalType.EBAY,
    fullName: 'EBAY_PORTAL_DISPLAY_NAME_L',
    userNameLabel: 'EBAY_PORTAL_USERNAME_L',
    passwordLabel: 'EBAY_PORTAL_PASSWORD_L',
    imgUrl: '/assets/images/portals/kleinanzeigen-logo.svg',
    logoUrl: '/assets/images/portal-logos/ebay.png', // Added for displaying logo with limited space
    skipValidation: false
  },
  {
    portalId: PortalType.IMMOBILIENSCOUT24_DE,
    fullName: 'IMMOBILIENSCOUT24_DE_PORTAL_DISPLAY_NAME_L',
    iframeUrl: `/cloud/immoscout/oauth`,
    imgUrl: '/assets/images/portals/immoscout24_logo.png',
    validationControl: 'verificationCode',
    skipValidation: true,
    subType: ImmoscoutTypes.PORTAL
  },
  {
    portalId: PortalType.IMMONET_DE,
    fullName: 'IMMONET_DE_PORTAL_DISPLAY_NAME_L',
    userNameLabel: 'IMMONET_DE_PORTAL_USERNAME_L',
    passwordLabel: 'IMMONET_DE_PORTAL_PASSWORD_L',
    imgUrl: '/assets/images/portals/immonet_logo.gif',
    skipValidation: false
  },
  {
    portalId: PortalType.IMMOWELT_DE,
    fullName: 'IMMOWELT_DE_PORTAL_DISPLAY_NAME_L',
    userNameLabel: 'IMMOWELT_DE_PORTAL_USERNAME_L',
    passwordLabel: 'IMMOWELT_DE_PORTAL_PASSWORD_L',
    imgUrl: '/assets/images/portals/immowelt_logo.jpg',
    skipValidation: false
  },
  {
    portalId: PortalType.IVD,
    fullName: 'IVD_PORTAL_DISPLAY_NAME_L',
    userNameLabel: 'IVD_PORTAL_USERNAME_L',
    passwordLabel: 'IVD_PORTAL_PASSWORD_L',
    imgUrl: '/assets/images/portals/ivd24_logo.png',
    skipValidation: false
  },
  {
    portalId: PortalType.IMMOBILIENSCOUT24_HM_DE,
    fullName: 'IMMOBILIENSCOUT24_HM_DE_PORTAL_DISPLAY_NAME_L',
    iframeUrl: `/cloud/immoscout/oauth`,
    imgUrl: '/assets/images/portals/immoscout24_logo.png',
    validationControl: 'verificationCode',
    skipValidation: true,
    subType: ImmoscoutTypes.MODULE
  },
  {
    portalId: PortalType.IMMOBILIENSCOUT24_GC_DE,
    fullName: 'IMMOBILIENSCOUT24_GC_DE_PORTAL_DISPLAY_NAME_L',
    iframeUrl: `/cloud/immoscout/oauth`,
    imgUrl: '/assets/images/portals/immoscout24_logo.png',
    validationControl: 'channel',
    skipValidation: true,
    subType: ImmoscoutTypes.CUSTOM
  },
  {
    portalId: PortalType.RESIDENT_APP,
    fullName: 'RESIDENT_APP_PORTAL_DISPLAY_NAME_L',
    imgUrl: '/assets/images/portals/resident_app.svg',
    skipValidation: true
  },
  {
    portalId: PortalType.WILLHABEN,
    fullName: 'WILLHABEN_PORTAL_DISPLAY_NAME_L',
    userNameLabel: 'WILLHABEN_PORTAL_USERNAME_L',
    passwordLabel: 'WILLHABEN_PORTAL_PASSWORD_L',
    imgUrl: '/assets/images/portals/willhaben-logo.svg',
    validationControl: 'password',
    skipValidation: false
  },
  {
    portalId: PortalType.IMMOBILIE1,
    fullName: 'IMMOBILIE1_PORTAL_DISPLAY_NAME_L',
    userNameLabel: 'IMMOBILIE1_PORTAL_USERNAME_L',
    passwordLabel: 'IMMOBILIE1_PORTAL_PASSWORD_L',
    imgUrl: '/assets/images/portals/immobilie1-logo.png',
    validationControl: 'password',
    skipValidation: false
  }
];
