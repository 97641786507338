import { Component, ViewContainerRef, inject } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';

import { ToastService } from 'libs/components/legacy/toast';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { fadeOnEnterLeaveAnimation } from 'libs/utils';

import { I18nService, PageTitleService } from 'libs/infrastructure';
import { AsyncPipe } from '@angular/common';
import { LoadingSpinnerComponent } from 'libs/components/legacy';
import { ThemeDirective } from 'libs/infrastructure/theme/theme.directive';
import { ThemeComponent } from 'libs/infrastructure/theme/components/theme/theme.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fadeOnEnterLeaveAnimation],
  imports: [
    AsyncPipe,
    LoadingSpinnerComponent,
    RouterOutlet,
    ThemeDirective,
    ThemeComponent
  ],
  standalone: true
})
export class AppComponent {
  toastService = inject(ToastService);
  viewContainerRef = inject(ViewContainerRef);
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private activatedRoute = inject(ActivatedRoute);
  private pageTitleService = inject(PageTitleService);
  private i18nService = inject(I18nService);

  public finishedLoaded$: Observable<boolean>;

  public constructor() {
    const viewContainerRef = this.viewContainerRef;

    this.pageTitleService.run(this.activatedRoute);
    this.i18nService.loadDefaultTranslations();
    this.toastService.setRootViewContainerRef(viewContainerRef);

    this.finishedLoaded$ = this.store.select(
      fromBaseState.getTranslationLoaded
    );
  }
}
