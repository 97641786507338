<form [formGroup]="form">
  <app-form-field>
    <app-form-field-label>{{
      'PORTAL_NAME_L' | translate
    }}</app-form-field-label>
    <input
      appInput
      type="text"
      required
      [placeholder]="'TYPE_PORTAL_NAME_L' | translate"
      formControlName="name"
      class="form-control mb10"
    />
  </app-form-field>
</form>
