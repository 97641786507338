import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  inject
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';

import {
  PortalConfig,
  HomepageModule,
  ImmomioPortalType
} from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from 'libs/components';
import { PORTALS_CONFIG } from '../../tokens';

@Component({
  selector: 'app-portal-homepage-list',
  templateUrl: './portal-homepage-list.component.html',
  styleUrls: ['./portal-homepage-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PortalHomepageListComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [ComponentsModule, TranslateModule]
})
export class PortalHomepageListComponent implements ControlValueAccessor {
  private portalsConfig = inject(PORTALS_CONFIG);

  @Input() homepages: HomepageModule[] = [];

  @Input() allowSelect = false;
  @Input() disabled = false;

  @Output() remove = new EventEmitter();

  public BadgeColorEnum = BadgeColorEnum;

  public value: HomepageModule[] = [];
  public config: PortalConfig; // instead of getConfig() in PortalCredentialList

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  constructor() {
    this.config = this.portalsConfig.find(
      config => config.portalId === ImmomioPortalType.HOMEPAGE_MODULE
    );
  }

  public isSelected(homepage: HomepageModule) {
    return this.value?.some(c => c?.id === homepage.id);
  }

  public onToggle(homepage: HomepageModule) {
    if (this.isSelected(homepage)) {
      this.value = this.value.filter(c => c?.id !== homepage.id);
    } else {
      this.value = [...this.value, homepage];
    }

    this.onChange(this.value);
    this.onTouch();
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public writeValue(value: HomepageModule[]) {
    this.value = value;
  }
}
