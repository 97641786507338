import { defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,

  production: false,
  development: false,
  deploymentEnv: 'integration',
  file_upload_path: 'https://gql-adm.itg.immomio.com/admin/fileUpload',
  graphql_server_uri: 'https://gql-adm.itg.immomio.com/admin/graphql',
  ftp_url: 'ftp.itg.immomio.com',
  backend_url: 'https://api-srv.itg.immomio.com',
  landlord_app_url: 'https://app.itg.immomio.com',
  tenant_app_url: 'https://tenant.itg.immomio.com'
};
