import { InjectionToken } from '@angular/core';

import { PortalConfig } from '@ui/shared/models';

export const PORTALS_CONFIG = new InjectionToken<PortalConfig[]>(
  'PORTALS_CONFIG'
);
export const PORTALS_CONFIG_MAP = new InjectionToken<{
  [key: string]: PortalConfig;
}>('PORTALS_CONFIG_MAP');
